// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$agrojabes-app-primary: mat.define-palette(mat.$green-palette);
$agrojabes-app-accent: mat.define-palette(mat.$purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$agrojabes-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$agrojabes-app-theme: mat.define-light-theme((
    color: (
        primary: $agrojabes-app-primary,
        accent: $agrojabes-app-accent,
        warn: $agrojabes-app-warn,
    )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($agrojabes-app-theme);
@include mat.all-component-typographies($agrojabes-app-theme);

@for $i from 0 through 24 {
    .mat-mdc-card.mat-elevation-z#{$i} {
        @include mat.elevation($i);
    }
}
