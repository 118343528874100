/* You can add global styles to this file, and also import other style files */
@import 'material';
@import 'breakpoints';
@import 'variables';

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: $agro-fonts;
}
